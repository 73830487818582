import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Bounce } from 'react-reveal';
import { NavLink, useParams } from 'react-router-dom';
import { Button,  Icon, Popup, Select, Tab, Form, TextArea, Modal} from 'semantic-ui-react';
import SKLT from '../../AssetsM/usedSlk';
import GConf from '../../AssetsM/generalConf';
import BreadCrumb from '../Assets/breadCrumb'
import { toast } from 'react-toastify';
import FrameForPrint from '../Assets/frameForPrint';
import usePrintFunction from '../Assets/Hooks/printFunction';
import { Input } from 'semantic-ui-react';
import TableGrid from '../Assets/tableGrid';
import { _ } from 'gridjs-react';

const AjouterCard = ({budgetListGenres, setTheInputData, theInputData, AjouterAuxListe}) =>{
  const GenreIsSelectedFunc = (selectedGenre) => {
    //setTheInputData({...theInputData, Subgenre : selectedGenre})

    switch (selectedGenre) {
      case 'DEPENSES':
        setTheInputData({...theInputData, Subgenre : selectedGenre, BD_Genre : 'OUTPUT'})
        break;
      case 'DEPOT':
        setTheInputData({...theInputData, Subgenre : selectedGenre, BD_Genre : 'OUTPUT'})
        break;
      case 'REVENUE':
        setTheInputData({...theInputData, Subgenre : selectedGenre, BD_Genre : 'INPUT'})
        break;
      case 'BONS':
        setTheInputData({...theInputData, Subgenre : selectedGenre, BD_Genre : 'INPUT'})
        break;
      case 'SAREF':
        setTheInputData({...theInputData, Subgenre : selectedGenre, BD_Genre : 'INPUT'})
        break;

      default:
        break;
    }
  }
  return(<>
          <div className='card card-body shadow-sm mb-2'>
              <h5 className='mb-1'>Liste des prix: </h5>
              <Select placeholder='Selectionnez' options={budgetListGenres}  onChange={(e, { value }) => GenreIsSelectedFunc(value)}   className='w-100 shadow-sm rounded mb-3'  />
              <Input type='number'  placeholder='Valeur' onChange={(e) => setTheInputData({...theInputData, BD_Value : e.target.value})} className='w-100 shadow-sm rounded mb-3' />
              <Input type='date'  placeholder='Date' value={theInputData.DB_Date}  onChange={(e) => setTheInputData({...theInputData, DB_Date : e.target.value})} className='w-100 shadow-sm rounded mb-3' />
              <Form>
                <TextArea className='mb-2' onChange={(e) => setTheInputData({...theInputData, DB_Description : e.target.value})} placeholder='Tell us more' />
              </Form>
              <Button  className='rounded-pill btn-imprimer mt-2'  fluid onClick={ (e) => AjouterAuxListe()}><Icon name='plus' /> Ajouter Aux Liste </Button>
          </div>
  </>)
}
const AjouterCaissesCard = ({setTheCaisseData, theCaisseData, AjouterAuxCaisseListe}) =>{
return(<>
        <div className='card card-body shadow-sm mb-2'>
            <h5 className='mb-1'>Liste des prix: </h5>
             
            <Input type='date'  placeholder='Valeur' value={theCaisseData.RC_Date}  onChange={(e) => setTheCaisseData({...theCaisseData, RC_Date : e.target.value})} className='w-100 shadow-sm rounded mb-3' />
            <Input type='number'  placeholder='Caisse 1' onChange={(e) => setTheCaisseData({...theCaisseData, Caisse_1 : e.target.value})} className='w-100 shadow-sm rounded mb-3' />
            <Input type='number'  placeholder='Caisse 2' onChange={(e) => setTheCaisseData({...theCaisseData, Caisse_2 : e.target.value})} className='w-100 shadow-sm rounded mb-3' />
            <Button  className='rounded-pill btn-imprimer'  fluid onClick={ (e) => AjouterAuxCaisseListe()}><Icon name='plus' /> Ajouter Aux Liste </Button>
        </div>
</>)
}
const AjouterElectroCard = ({setTheElectroData, theElectroData, AjouterAuxElectroListe}) =>{
return(<>
        <div className='card card-body shadow-sm mb-2'>
            <h5 className='mb-1'>Liste des prix: </h5>
             
            <Input type='date' value={theElectroData.De_Date}  onChange={(e) => setTheElectroData({...theElectroData, De_Date : e.target.value})} placeholder='Valeur' className='w-100 shadow-sm rounded mb-3' />
            <Input type='number'  placeholder='Montant de depart' onChange={(e) => setTheElectroData({...theElectroData, Carnet_Value : e.target.value})} className='w-100 shadow-sm rounded mb-3' />
             
            <Button  className='rounded-pill btn-imprimer'  fluid onClick={ (e) => AjouterAuxElectroListe()}><Icon name='plus' /> Ajouter Aux Liste </Button>
        </div>
</>)
}

const ToEditCard = ({budgetListGenres, setBudgetToEdit, budgetToEdit, ModifierBudgetListe}) =>{
  const GenreIsSelectedFunc = (selectedGenre) => {
    //setBudgetToEdit({...budgetToEdit, Subgenre : selectedGenre})

    switch (selectedGenre) {
      case 'DEPENSES':
        setBudgetToEdit({...budgetToEdit, Subgenre : selectedGenre, BD_Genre : 'OUTPUT'})
        break;
      case 'DEPOT':
        setBudgetToEdit({...budgetToEdit, Subgenre : selectedGenre, BD_Genre : 'OUTPUT'})
        break;
      case 'REVENUE':
        setBudgetToEdit({...budgetToEdit, Subgenre : selectedGenre, BD_Genre : 'INPUT'})
        break;
      case 'BONS':
        setBudgetToEdit({...budgetToEdit, Subgenre : selectedGenre, BD_Genre : 'INPUT'})
        break;
      case 'SAREF':
        setBudgetToEdit({...budgetToEdit, Subgenre : selectedGenre, BD_Genre : 'INPUT'})
        break;

      default:
        break;
    }
  }
  return(<>
          <div className='  mb-2'>
              <h5 className='mb-1'>Liste des prix: </h5>
              <Select placeholder='Selectionnez' value={budgetToEdit.Subgenre} options={budgetListGenres}  onChange={(e, { value }) => GenreIsSelectedFunc(value)}   className='w-100 shadow-sm rounded mb-3'  />
              <Input type='number'  placeholder='Valeur' value={budgetToEdit.BD_Value} onChange={(e) => setBudgetToEdit({...budgetToEdit, BD_Value : e.target.value})} className='w-100 shadow-sm rounded mb-3' />
              <Input type='date'  placeholder='Date' value={budgetToEdit.DB_Date}  onChange={(e) => setBudgetToEdit({...budgetToEdit, DB_Date : e.target.value})} className='w-100 shadow-sm rounded mb-3' />
              <Form>
                <TextArea className='mb-2' value={budgetToEdit.DB_Description} onChange={(e) => setBudgetToEdit({...budgetToEdit, DB_Description : e.target.value})} placeholder='Tell us more' />
              </Form>
              <Button  className='rounded-pill btn-imprimer mt-2'  fluid onClick={ (e) => ModifierBudgetListe()}><Icon name='plus' /> Ajouter Aux Liste </Button>
          </div>
  </>)
}
const ToEditCaissesCard = ({setCaisseRToEdit, caisseRToEdit, ModifierCaisseListe}) =>{
return(<>
        <div className='  mb-2'>
            <h5 className='mb-1'>Liste des prix: </h5>
             
            <Input type='date'  placeholder='Valeur' value={caisseRToEdit.RC_Date}  onChange={(e) => setCaisseRToEdit({...caisseRToEdit, RC_Date : e.target.value})} className='w-100 shadow-sm rounded mb-3' />
            <Input type='number'  placeholder='Caisse 1'  value={caisseRToEdit.Caisse_1} onChange={(e) => setCaisseRToEdit({...caisseRToEdit, Caisse_1 : e.target.value})} className='w-100 shadow-sm rounded mb-3' />
            <Input type='number'  placeholder='Caisse 2'  value={caisseRToEdit.Caisse_2} onChange={(e) => setCaisseRToEdit({...caisseRToEdit, Caisse_2 : e.target.value})} className='w-100 shadow-sm rounded mb-3' />
            <Button  className='rounded-pill btn-imprimer'  fluid onClick={ (e) => ModifierCaisseListe()}><Icon name='plus' /> Ajouter Aux Liste </Button>
        </div>
</>)
}
const ToEditElectroCard = ({setElectroToEdit, electroToEdit, ModifierElectroListe}) =>{
return(<>
        <div className='  mb-2'>
            <h5 className='mb-1'>Liste des prix: </h5>
             
            <Input type='date' value={electroToEdit.De_Date}  onChange={(e) => setElectroToEdit({...electroToEdit, De_Date : e.target.value})} placeholder='Valeur' className='w-100 shadow-sm rounded mb-3' />
            <Input type='number'  value={electroToEdit.Carnet_Value} placeholder='Montant de depart' onChange={(e) => setElectroToEdit({...electroToEdit, Carnet_Value : e.target.value})} className='w-100 shadow-sm rounded mb-3' />
             
            <Button  className='rounded-pill btn-imprimer'  fluid onClick={ (e) => ModifierElectroListe()}><Icon name='plus' /> Ajouter Aux Liste </Button>
        </div>
</>)
}

function TicketDePrixPage() {
    /*#########################[Const]##################################*/
    let [caissesR, setCaisseR] = useState([])
    let [electroR, setElectroR] = useState([])
    let [Revenue, seRevenueData] = useState([])
    let [depenseData, setDepenseData] = useState([])
    let [sarefData, setSarefData] = useState([])
    let [bonsData, setBonsData] = useState([])
    let [mgDepotData, setMgDepotData] = useState([])
    let [theInputData, setTheInputData] = useState({BD_Genre:'', Subgenre:'', DB_Date: new Date().toISOString().split('T')[0], BD_Value:'', DB_Description:''})
    let [theElectroData, setTheElectroData] = useState({De_Date: new Date().toISOString().split('T')[0], Carnet_Value:''})
    let [theCaisseData, setTheCaisseData] = useState({RC_Date: new Date().toISOString().split('T')[0], Caisse_1:'', Caisse_1:''})
    let [budgetData, setBudgetData] = useState([])

    const [loading , setLoading] = useState(false)
    const [modalS, setModalS] = useState(false)
    
    const [editCaisseR, setEditCaisse] = useState(false)
    const [editBudget, setEditBudget] = useState(false)
    const [editElectro, setEditElectro] = useState(false)
    
    let [caisseRToEdit, setCaisseRToEdit] = useState({})
    let [electroToEdit, setElectroToEdit] = useState({})
    let [budgetToEdit, setBudgetToEdit] = useState({})
     
    const budgetListGenres = [
      {id:1, text:'DEPENSES', value:'DEPENSES'},
      {id:1, text:'DEPOT', value:'DEPOT'},
      {id:1, text:'REVENUE', value:'REVENUE'},
      {id:1, text:'BONS', value:'BONS'},
      {id:1, text:'SAREF', value:'SAREF'},
    ]
    
    const panes = [
      {
        menuItem: { key: 'attent',  content: <span className='text-secondary'><b><span className='bi bi-plus'></span> CAISSES</b></span> , className:'rounded-pill'},
        render: () => <TableGrid tableData={caissesR} columns={['Date','Caisse1','Caisse2','Modifier','Voir']} />,
      },
      {
        menuItem: { key: 'vu',  content: <span className='text-secondary'><b><span className='bi bi-plus'></span> REVENUE </b></span> , className:'rounded-pill' },
        render: () => <TableGrid tableData={Revenue} columns={['Date','Valeur', 'description','Modifier','Voir']} />,
      },
      {
        menuItem: { key: 'accept',  content: <span className='text-secondary'><b><span className='bi bi-plus'></span> ELECTRO</b></span> , className:'rounded-pill' },
        render: () => <TableGrid tableData={electroR} columns={['De','Vers', 'Valeur','Modifier','Voir']} />,
      },
      {
        menuItem: { key: 'dthyrtrt',  content: <span className='text-secondary'><b><span className='bi bi-plus'></span> SAREF</b></span>, className:'rounded-pill' },
        render: () => <TableGrid tableData={sarefData} columns={['Date','Valeur', 'description','Modifier','Voir']} />,
      },
      {
        menuItem: { key: 'fjfggf',  content: <span className='text-secondary'><b><span className='bi bi-plus'></span> BONS</b></span>, className:'rounded-pill' },
        render: () => <TableGrid tableData={bonsData} columns={['Date','Valeur', 'description','Modifier','Voir']} />,
      },
      {
        menuItem: { key: 'refuse',  content: <span className='text-secondary'><b><span className='bi bi-dash'></span> DEPENSES</b></span>, className:'rounded-pill' },
        render: () => <TableGrid tableData={depenseData} columns={['Date','Valeur', 'description','Modifier','Voir']} />,
      },
      {
        menuItem: { key: 'dthrtrt',  content: <span className='text-secondary'><b><span className='bi bi-dash'></span> DEPOT</b></span>, className:'rounded-pill' },
        render: () => <TableGrid tableData={mgDepotData} columns={['Date','Valeur', 'description','Modifier','Voir']} />,
      },
      
    ]

    const panesAdd = [
      {
        menuItem: { key: 'attent',  content: <span className='text-secondary'><b> AJOUTER</b></span> },
        render: () => <AjouterCard budgetListGenres={budgetListGenres} setTheInputData={setTheInputData} theInputData={theInputData} AjouterAuxListe={AjouterAuxListe} />,
      },
      {
        menuItem: { key: 'vu',  content: <span className='text-secondary'><b> CAISSES </b></span>  },
        render: () => <AjouterCaissesCard   setTheCaisseData={setTheCaisseData} theCaisseData={theCaisseData} AjouterAuxCaisseListe={AjouterAuxCaisseListe} />,
      },
      {
        menuItem: { key: 'accept',  content: <span className='text-secondary'><b> ELECTRO </b></span>  },
        render: () => <AjouterElectroCard setTheElectroData={setTheElectroData} theElectroData={theElectroData} AjouterAuxElectroListe={AjouterAuxElectroListe} />,
      },
       
      
    ]
    /*#########################[UseEffect]##################################*/
    
    useEffect(() => {
      axios.post(`https://api.system.ansl.tn/API/Admin/familia/finance`, {
          TAG: 'TAG',
      })
      .then(function (response) {

              let articleListContainer = []
              response.data.caissesR.map( (getData) => articleListContainer.push([
              getData.RC_Date,
              parseInt(getData.Caisse_1).toFixed(3),
              parseInt(getData.Caisse_2).toFixed(3),
              _(<Button className='rounded-pill' size='mini' onClick={ (e) => openEditModal('CaisseR', getData)}> Modifier  </Button>),
              _(<Button className='rounded-pill bg-system-btn' size='mini' onClick={ (e) => alert((parseInt(getData.Caisse_1)+parseInt(getData.Caisse_2)).toFixed(3))}><span className='d-none d-lg-inline'> Info </span><Icon  name='angle right' /></Button>)
              ],))
              setCaisseR(articleListContainer) 
              
              let electroCont = []
              response.data.electroR.map( (getData) => electroCont.push([
                  getData.De_Date,
                  getData.Vers_Date,
                  getData.Carnet_Value,
                  _(<Button className='rounded-pill' size='mini' onClick={ (e) => openEditModal('Electro', getData)}> Modifier  </Button>),
                  _(<Button className='rounded-pill bg-system-btn' size='mini' onClick={ (e) => alert(getData.Description)}><span className='d-none d-lg-inline'> Info </span><Icon  name='angle right' /></Button>)
              ],))
              setElectroR(electroCont)

              let revenueCount = []
              let RevenueList = response.data.budgetData.filter((data) => data.Subgenre === 'REVENUE')
               
                  RevenueList.map( (getData) => revenueCount.push([
                  getData.DB_Date,
                  getData.BD_Value,
                  getData.DB_Description,
                  _(<Button className='rounded-pill' size='mini' onClick={ (e) => openEditModal('Budget', getData)}> Modifier  </Button>),
                  _(<Button className='rounded-pill bg-system-btn' size='mini' onClick={ (e) => alert(getData.DB_Description)}><span className='d-none d-lg-inline'> Info </span><Icon  name='angle right' /></Button>)
              ],))
              seRevenueData(revenueCount)

              let depenseCount = []
              let DepenseList = response.data.budgetData.filter((data) => data.Subgenre === 'DEPENSES')
               
                  DepenseList.map( (getData) => depenseCount.push([
                  getData.DB_Date,
                  getData.BD_Value,
                  getData.DB_Description,
                  _(<Button className='rounded-pill' size='mini' onClick={ (e) => openEditModal('Budget', getData)}> Modifier  </Button>),
                  _(<Button className='rounded-pill bg-system-btn' size='mini' onClick={ (e) => alert(getData.DB_Description)}><span className='d-none d-lg-inline'> Info </span><Icon  name='angle right' /></Button>)
              ],))
              setDepenseData(depenseCount)

              let depotCount = []
              let DepotList = response.data.budgetData.filter((data) => data.Subgenre === 'DEPOT')
               
                  DepotList.map( (getData) => depotCount.push([
                  getData.DB_Date,
                  parseInt(getData.BD_Value).toFixed(3),
                  getData.DB_Description,
                  _(<Button className='rounded-pill' size='mini' onClick={ (e) => openEditModal('Budget', getData)}> Modifier  </Button>),
                  _(<Button className='rounded-pill bg-system-btn' size='mini' onClick={ (e) => alert(getData.DB_Description)}><span className='d-none d-lg-inline'> Info </span><Icon  name='angle right' /></Button>)
              ],))
              setMgDepotData(depotCount)

              let sarefCount = []
              let SarefList = response.data.budgetData.filter((data) => data.Subgenre === 'SAREF')
               
                  SarefList.map( (getData) => sarefCount.push([
                  getData.DB_Date,
                  parseInt(getData.BD_Value).toFixed(3),
                  getData.DB_Description,
                  _(<Button className='rounded-pill' size='mini' onClick={ (e) => openEditModal('Budget', getData)}> Modifier  </Button>),
                  _(<Button className='rounded-pill bg-system-btn' size='mini' onClick={ (e) => alert(getData.DB_Description)}><span className='d-none d-lg-inline'> Info </span><Icon  name='angle right' /></Button>)
              ],))
              setSarefData(sarefCount)

              let bonsCount = []
              let BonsList = response.data.budgetData.filter((data) => data.Subgenre === 'BONS')
               
                  BonsList.map( (getData) => bonsCount.push([
                  getData.DB_Date,
                  parseInt(getData.BD_Value).toFixed(3),
                  getData.DB_Description,
                  _(<Button className='rounded-pill' size='mini' onClick={ (e) => openEditModal('Budget', getData)}> Modifier  </Button>),
                  _(<Button className='rounded-pill bg-system-btn' size='mini' onClick={ (e) => alert(getData.DB_Description)}><span className='d-none d-lg-inline'> Info </span><Icon  name='angle right' /></Button>)
              ],))
              setBonsData(bonsCount)
           
          setBudgetData(response.data.budgetData)
          
      }).catch((error) => {
          if(error.request) {
          
          }
        });
    },[])

    /*#########################[Function]##################################*/
    const PrintFunction = (frameId) =>{ usePrintFunction(frameId)}

    const DeleteListe =  () =>{
        axios.post(`${GConf.ApiLink}/tools/ticket/prix/supprimer`, {
            PID : GConf.PID,
          })
          .then(function (response) {
                toast.success('Liste Vide', GConf.TostSuucessCaisseGonf)
                setLoading(true)
          }).catch((error) => {
            if(error.request) {
              toast.error(<><div><h5>Probleme de Connextion</h5>     </div></>, GConf.TostInternetGonf)   
              setLoading(true)
              
            }
          });
    }
    const AjouterAuxListe =  () =>{
       
        if (!theInputData.BD_Genre) {toast.error("Matricule Invalide !", GConf.TostErrorGonf)}
        else if (!theInputData.Subgenre) {toast.error("Nom Invalide !", GConf.TostErrorGonf)}
        else if (!theInputData.DB_Date) {toast.error("Phone Invalide !", GConf.TostErrorGonf)}
        else if (!theInputData.BD_Value) {toast.error("Adresee Invalide !", GConf.TostErrorGonf)}
        else if (!theInputData.DB_Description) {toast.error("Adresee Invalide !", GConf.TostErrorGonf)}
        else{
            axios.post(`${GConf.ApiLink}/tools/budget/ajouter`, {
                theInputData : theInputData,
            }).then(function (response) {
                console.log(response.data)
            }).catch((error) => {
                if(error.request) {
                  toast.error(<><div><h5>Probleme de Connextion</h5> Le client sera enregistrer sur votre ordinateur   </div></>, GConf.TostInternetGonf)     
                }
              });
                    
        }
    }
    const AjouterAuxCaisseListe =  () =>{
       
      if (!theCaisseData.RC_Date) {toast.error("Matricule Invalide !", GConf.TostErrorGonf)}
      else if (!theCaisseData.Caisse_1) {toast.error("Nom Invalide !", GConf.TostErrorGonf)}
      else if (!theCaisseData.Caisse_2) {toast.error("Phone Invalide !", GConf.TostErrorGonf)}
      else{
          axios.post(`${GConf.ApiLink}/tools/budget/ajouter/caisse`, {
              theCaisseData : theCaisseData,
          }).then(function (response) {
              console.log(response.data)
          }).catch((error) => {
              if(error.request) {
                toast.error(<><div><h5>Probleme de Connextion</h5> Le client sera enregistrer sur votre ordinateur   </div></>, GConf.TostInternetGonf)     
              }
            });
                  
      }
    }
    const AjouterAuxElectroListe =  () =>{
       
      if (!theElectroData.De_Date) {toast.error("Matricule Invalide !", GConf.TostErrorGonf)}
      else if (!theElectroData.Carnet_Value) {toast.error("Nom Invalide !", GConf.TostErrorGonf)}
      else{
          axios.post(`${GConf.ApiLink}/tools/budget/ajouter/electro`, {
              theElectroData : theElectroData,
          }).then(function (response) {
              console.log(response.data)
          }).catch((error) => {
              if(error.request) {
                toast.error(<><div><h5>Probleme de Connextion</h5> Le client sera enregistrer sur votre ordinateur   </div></>, GConf.TostInternetGonf)     
              }
            });
                  
      }
    }

    const ModifierBudgetListe =  () =>{
       
      if (!theInputData.BD_Genre) {toast.error("Matricule Invalide !", GConf.TostErrorGonf)}
      else if (!theInputData.Subgenre) {toast.error("Nom Invalide !", GConf.TostErrorGonf)}
      else if (!theInputData.DB_Date) {toast.error("Phone Invalide !", GConf.TostErrorGonf)}
      else if (!theInputData.BD_Value) {toast.error("Adresee Invalide !", GConf.TostErrorGonf)}
      else if (!theInputData.DB_Description) {toast.error("Adresee Invalide !", GConf.TostErrorGonf)}
      else{
          axios.post(`${GConf.ApiLink}/tools/budget/modifier`, {
              theInputData : theInputData,
          }).then(function (response) {
              console.log(response.data)
          }).catch((error) => {
              if(error.request) {
                toast.error(<><div><h5>Probleme de Connextion</h5> Le client sera enregistrer sur votre ordinateur   </div></>, GConf.TostInternetGonf)     
              }
            });
                  
      }
    }
    const ModifierCaisseListe =  () =>{
      
      if (!theCaisseData.RC_Date) {toast.error("Matricule Invalide !", GConf.TostErrorGonf)}
      else if (!theCaisseData.Caisse_1) {toast.error("Nom Invalide !", GConf.TostErrorGonf)}
      else if (!theCaisseData.Caisse_2) {toast.error("Phone Invalide !", GConf.TostErrorGonf)}
      else{
          axios.post(`${GConf.ApiLink}/tools/budget/modifier/caisse`, {
              theCaisseData : theCaisseData,
          }).then(function (response) {
              console.log(response.data)
          }).catch((error) => {
              if(error.request) {
                toast.error(<><div><h5>Probleme de Connextion</h5> Le client sera enregistrer sur votre ordinateur   </div></>, GConf.TostInternetGonf)     
              }
            });
                  
      }
    }
    const ModifierElectroListe =  () =>{
      
      if (!theElectroData.De_Date) {toast.error("Matricule Invalide !", GConf.TostErrorGonf)}
      else if (!theElectroData.Carnet_Value) {toast.error("Nom Invalide !", GConf.TostErrorGonf)}
      else{
          axios.post(`${GConf.ApiLink}/tools/budget/modifier/electro`, {
              theElectroData : theElectroData,
          }).then(function (response) {
              console.log(response.data)
          }).catch((error) => {
              if(error.request) {
                toast.error(<><div><h5>Probleme de Connextion</h5> Le client sera enregistrer sur votre ordinateur   </div></>, GConf.TostInternetGonf)     
              }
            });
                  
      }
    }

    const openEditModal = (genre,selected) =>{
      switch (genre) {
        case 'CaisseR': setCaisseRToEdit(selected) ; setEditCaisse(true) 
        break;

        case 'Budget': setBudgetToEdit(selected) ; setEditBudget(true) 
        break;

        case 'Electro': setElectroToEdit(selected) ; setEditElectro(true) 
        break;
      
        default:
          break;
      }
      setModalS(true)
    }
    
    /*#########################[Card]##################################*/
    
    

    return ( <>
            <div className='container'>
                <h5>Budget Totale</h5> 
                <br />
                <div className="row">
                    <div className="col-12 col-lg-4">
                            <Tab menu={{width : panes.width,  secondary: false }} panes={panesAdd} />
                    </div>
                    <div className="col-12 col-lg-8">
                         <Tab menu={{ secondary: true }} panes={panes} />
                    </div>
                    
                </div>
                   
            </div>
            <Modal
                size='small'
                open={editCaisseR}
                closeIcon
                onClose={() => setEditCaisse(false)}
                onOpen={() => setEditCaisse(true)}
            >
                <Modal.Header><h4>Caisse R</h4></Modal.Header>
                <Modal.Content scrolling>
                    <ToEditCaissesCard   setCaisseRToEdit={setCaisseRToEdit} caisseRToEdit={caisseRToEdit} ModifierCaisseListe={ModifierCaisseListe} />
                        
                </Modal.Content>
                <Modal.Actions>
                            {/* <Button className='rounded-pill bg-success'   onClick={ (e) => alert(`ok`)}> Modifier  </Button> */}
                </Modal.Actions>
            </Modal>

            <Modal
                size='small'
                open={editBudget}
                closeIcon
                onClose={() => setEditBudget(false)}
                onOpen={() => setEditBudget(true)}
            >
                <Modal.Header><h4>Budget</h4></Modal.Header>
                <Modal.Content scrolling>
                  <ToEditCard budgetListGenres={budgetListGenres} setBudgetToEdit={setBudgetToEdit} budgetToEdit={budgetToEdit} ModifierBudgetListe={ModifierBudgetListe} />
                        
                </Modal.Content>
                <Modal.Actions>
                            {/* <Button className='rounded-pill bg-success'   onClick={ (e) => alert(`ok`)}> Modifier  </Button> */}
                </Modal.Actions>
            </Modal>

            <Modal
                size='small'
                open={editElectro}
                closeIcon
                onClose={() => setEditElectro(false)}
                onOpen={() => setEditElectro(true)}
            >
                <Modal.Header><h4>Electro</h4></Modal.Header>
                <Modal.Content scrolling>
                    <ToEditElectroCard setElectroToEdit={setElectroToEdit} electroToEdit={electroToEdit} ModifierElectroListe={ModifierElectroListe} />   
                </Modal.Content>
                <Modal.Actions>
                            {/* <Button className='rounded-pill bg-success'   onClick={ (e) => alert(`ok`)}> Modifier  </Button> */}
                </Modal.Actions>
            </Modal>
    </> );
}

export default TicketDePrixPage;