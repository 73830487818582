import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Bounce } from 'react-reveal';
import { Button,  Form, Icon, Input, Loader, Modal, Pagination, TextArea } from 'semantic-ui-react';
import GConf from '../../AssetsM/generalConf';
import {toast } from 'react-toastify';
import FrameForPrint from '../Assets/frameForPrint';
import usePrintFunction from '../Assets/Hooks/printFunction';

function RapportPage() {
    const Today = new Date().toISOString().split('T')[0]
    const [rechercheDay, setRechercheDay] = useState({start:Today, end:Today});
    const [reternedeData, setReternedData] = useState({});

    const SearchRecette = () =>{
        axios.post(`${GConf.ApiLink}/tools/rapport`, {
            targetDay : rechercheDay
        })
        .then(function (response) {                
            console.log(response.data)
            setReternedData(response.data)

        }).catch((error) => {
            if(error.request) {
              toast.error(<><div><h5>Probleme de Connextion</h5>  </div></>, GConf.TostInternetGonf)   
            }
          });
    }

    const PrintFunction = (frameId) =>{ usePrintFunction(frameId)}
    return ( <>
            <div className='container'>
                <h5>Rapport </h5> 
                <br /> 
                <div className="row">
                <h5 className='text-danger'>Resumeé entre : </h5>
                <div className='mb-2 row'>
                    <div className='col-5 col-lg-4'><Input size='large' fluid  type='date' defaultValue={rechercheDay.start} onChange={(e) => setRechercheDay({...rechercheDay, start: e.target.value })} /></div>
                    <div className='col-5 col-lg-4'><Input size='large' fluid  type='date' defaultValue={rechercheDay.end} onChange={(e) => setRechercheDay({...rechercheDay, end: e.target.value })} /></div>
                    <div className='col-2 col-lg-4'><Button fluid className='btn-imprimer' size='large' icon onClick={(e) => SearchRecette()}>  <Icon name='print' /></Button></div>
                </div> 
                <div className='row mt-3'>
                    <div className='col-6'>
                        <div className='card shadow-sm border-div p-3'> 
                            <h5 className='text-start mb-1 mt-1'>Totale Vente :</h5>
                            <div className='text-start'>Caisse 1 : </div>
                            <div className='text-start'>Caisse 2 : </div>
                        </div>  
                    </div>
                    <div className='col-6'>
                        <div className='card shadow-sm border-div p-3'> 
                            <h5 className='text-start mb-1 mt-1'>Totale Depenses :</h5>
                            <div className='text-start'>Caisse 1 : </div>
                            <div className='text-start'>Caisse 2 : </div>
                        </div>  
                    </div>
                     
                </div>
                <br />
                <br />
                <div className='text-end mt-4'>
                <Button  className='rounded-pill btn-imprimer'    onClick={(e) => PrintFunction('printFacture')}><Icon name='edit outline' /> Imprimer</Button>
                </div>
                
                 
                
            </div>
            </div>
            <FrameForPrint frameId='printFacture' src={`/Pr/tools/resumer/${rechercheDay.start}/${rechercheDay.end}`} />
    </> );
}

export default RapportPage;