import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Bounce } from 'react-reveal';
import { Button,  Form, Icon, Input, Loader, Modal, Pagination, TextArea } from 'semantic-ui-react';
import GConf from '../../../AssetsM/generalConf';
import {toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

function RapportPage() {
    let {de,vers} = useParams()
    
    const [reternedeData, setReternedData] = useState({});
    let [caissesR, setCaisseR] = useState([])
    let [caissesD, setCaisseD] = useState([])
    let [electroR, setElectroR] = useState([])
    let [revenueListe, seRevenueData] = useState([])
    let [depenseData, setDepenseData] = useState([])
    let [sarefData, setSarefData] = useState([])
    let [bonsData, setBonsData] = useState([])
    let [mgDepotData, setMgDepotData] = useState([])

    useEffect(()=>{
        axios.post(`${GConf.ApiLink}/tools/rapport`, {
            targetDay : {start:de, end:vers}
        })
        .then(function (response) {                
             
            console.log(response.data)
            setReternedData(response.data)
            setCaisseR(response.data.revenueCaisse)
            setCaisseD(response.data.depenseCaisse)

            let RevenueList = response.data.budget.filter((data) => data.Subgenre === 'REVENUE')
            seRevenueData(RevenueList)

             
            let DepenseList = response.data.budget.filter((data) => data.Subgenre === 'DEPENSES')
            setDepenseData(DepenseList)

           
            let DepotList = response.data.budget.filter((data) => data.Subgenre === 'DEPOT')
            setMgDepotData(DepotList)
            
             
            let SarefList = response.data.budget.filter((data) => data.Subgenre === 'SAREF')
            setSarefData(SarefList)
            
          
            let BonsList = response.data.budget.filter((data) => data.Subgenre === 'BONS')
            setBonsData(BonsList)


        }).catch((error) => {
            if(error.request) {
              toast.error(<><div><h5>Probleme de Connextion</h5>  </div></>, GConf.TostInternetGonf)   
            }
          });
    },[])
    
    const WriteCaisseName = (caisseID) => {
        if (caisseID == 1111111111) { return 'CAISSE 1'
            
        } else {
            return 'CAISSE 2'
        }
    }

    return ( <>
            <div className='container'>
                <h5>Rapport de : {de} , vers : {vers} </h5> 
                <br /> 
                <div className="row">
                    <h5 className='text-danger'>REVENUE CAISSES  : </h5>
                    <table>
                        <tr>
                            <td>Date</td>
                            <td>Caisse 1</td>
                            <td>Caisse 2</td>
                        </tr>
                        {caissesR.map((data,index) => <tr key={index}> <td>{new Date(data.RC_Date).toLocaleDateString('fr-FR').split( '/' ).join( '-' )}</td>  <td>{data.Caisse_1}</td> <td>{data.Caisse_2}</td></tr>)}
                    </table>
                     

                    <h5 className='text-danger mt-4'>DEPENSES CAISSES  : </h5>
                    <table>
                        <tr>
                            <td>Date</td>
                            <td>Ciasse</td>
                            <td>Depenses</td>
                            <td>Description</td>
                        </tr>
                        {caissesD.map((data,index) => <tr key={index}><td>{new Date(data.D_Date).toLocaleDateString('fr-FR').split( '/' ).join( '-' )} </td>  <td> {WriteCaisseName(data.Caisse_ID)} </td>  <td> {data.Valeur}</td>  <td> {data.Description}</td></tr>)}
                    </table>

                    <h5 className='text-danger mt-4'>REVENUE INTERNE  : </h5>
                    <table>
                        <tr>
                            <td>Date</td>
                            <td>Valeur</td>
                            <td>Description</td>
                        </tr>
                        {revenueListe.map((data,index) => <tr key={index}>  <td> {new Date(data.DB_Date).toLocaleDateString('fr-FR').split( '/' ).join( '-' )} </td>  <td> {data.BD_Value} </td>  <td> {data.DB_Description} </td></tr>)}
                    </table>


                    <h5 className='text-danger mt-4'>DEPENSES INTERNE  : </h5>
                    <table>
                        <tr>
                            <td>Date</td>
                            <td>Valeur</td>
                            <td>Description</td>
                        </tr>
                        {depenseData.map((data,index) => <tr key={index}>  <td> {new Date(data.DB_Date).toLocaleDateString('fr-FR').split( '/' ).join( '-' )} </td>  <td> {data.BD_Value} </td>  <td> {data.DB_Description} </td></tr>)}
                    </table>
                    

                    <h5 className='text-danger mt-4'>MAGAZIN DEPOT   : </h5>
                    <table>
                        <tr>
                            <td>Date</td>
                            <td>Valeur</td>
                            <td>Description</td>
                        </tr>
                        {mgDepotData.map((data,index) => <tr key={index}>  <td> {new Date(data.DB_Date).toLocaleDateString('fr-FR').split( '/' ).join( '-' )} </td>  <td> {data.BD_Value} </td>  <td> {data.DB_Description} </td></tr>)}
                    </table>

                    <h5 className='text-danger mt-4'>SAREF  : </h5>
                    <table>
                        <tr>
                            <td>Date</td>
                            <td>Valeur</td>
                            <td>Description</td>
                        </tr>
                        {sarefData.map((data,index) => <tr key={index}>  <td> {new Date(data.DB_Date).toLocaleDateString('fr-FR').split( '/' ).join( '-' )} </td>  <td> {data.BD_Value} </td>  <td> {data.DB_Description} </td></tr>)}
                    </table>


                    <h5 className='text-danger mt-4'>BONS : </h5>
                    <table>
                        <tr>
                            <td>Date</td>
                            <td>Valeur</td>
                            <td>Description</td>
                        </tr>
                        {bonsData.map((data,index) => <tr key={index}>  <td> {new Date(data.DB_Date).toLocaleDateString('fr-FR').split( '/' ).join( '-' )} </td>  <td> {data.BD_Value} </td>  <td> {data.DB_Description} </td></tr>)}
                    </table>

                     

                </div>
            </div>
    </> );
}

export default RapportPage;